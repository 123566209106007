<template>
    <div class="menu-body"
        v-if="$oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committed_year"
                            v-model="committedYr" disabled>
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId" disabled
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">{{
                                plant.plantName
                            }}, {{ plant.regionCode }}</option>
                        </select>
                    </div>
                    <div style="width: 25%; margin-left: 10px; z-index: 4">
                        <div class="flex-div">
                            <div style="flex: 7">
                                <label for="region" class="form-label select-lable lable-text"> Pillar </label>
                                <div class="dropdown" @click="showDropdown">
                                    <b-form-input class="form-control select-text select-text-pd cust-st-icon"
                                        :placeholder="`Select Pillar`" v-model="selectedText"> </b-form-input>
                                </div>
                                <div id="multi-select-options" class="multi-select" v-if="showOption">
                                    <ul>
                                        <li v-for="(option, index) in pillarList" :key="index">

                                            <b-form-checkbox type="checkbox" :checked="option.checkedBox"
                                                :name="'pllr' + option.pillarId" class="radio" :value="option"
                                                v-model="selected" @change="onOptionCheck()"
                                                :disabled="option.approvalStatus">
                                                {{ option.regionName }}
                                            </b-form-checkbox>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <b-button pill variant="outline-secondary"
                                class="save-button save-btn primary-color validation-btn" @click="onConfirmClick">
                                Search
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="target-approval-accordion">
                <div class="accordion" role="tablist" v-if="pillarWiseMachine.length">
                    <div v-for="(actionMain, actionMainIndex) in pillarWiseMachine" :key="(actionMain, actionMainIndex)"
                        class="add-top-border">
                        <div :id="actionMain.pillarId">
                            <div class="level-status target-card-lable principle-name-label"
                                @click="checkToggle(collapsed_values[actionMain.pillarCode], actionMain.pillarCode)">
                                <b-button :aria-controls="actionMain.pillarCode" class="img-button"
                                    :aria-expanded="collapsed_values[actionMain.pillarCode] ? 'true' : 'false'">
                                    <img alt="down" v-if="!collapsed_values[actionMain.pillarCode]"
                                        src="@/assets/accordian_down_arrow.svg" />
                                    <img alt="up" v-else src="@/assets/accordian_up_arrow.svg" />
                                </b-button>
                                {{ actionMain.pillarName + " (" + actionMain.pillarCode + ")" }}
                            </div>

                            <b-collapse v-bind:id="actionMain.pillarCode" v-model="collapsed_values[actionMain.pillarCode]">
                                <div>
                                    <b-table-simple responsive outlined id="machine-action-tracker" sticky-header
                                        class="machine-action-table scroll gn-table-b">
                                        <b-thead head-variant="light">
                                            <b-tr>
                                                <b-th v-for="header in machineDetailHeader" :key="header.key">
                                                    {{ header.label }}
                                                </b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody v-if="actionMain.machineDetail && actionMain.machineDetail.length">
                                            <b-tr v-for="(rowData, index) in actionMain.machineDetail" :key="index"
                                                :class="{ 'add-top-border': rowData.addBorder }">
                                                <b-td
                                                    v-if="rowData.hasOwnProperty('workCenter') && rowData.workCenter.hasOwnProperty('noOfMachine')"
                                                    :rowspan="rowData.workCenter.noOfMachine"
                                                    class="machine-action-table-machine">
                                                    {{ rowData.workCenter.name }}
                                                </b-td>
                                                <b-td class="machine-action-tracker-label">
                                                    {{ rowData.machines }}
                                                </b-td>

                                                <b-td>
                                                    <div class="input-div" v-if="rowData.jan.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.jan.value" min="0"
                                                            :disabled="!rowData.jan.needInput"
                                                            @input="changingF(index, 'jan')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.jan }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.feb.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.feb.value" min="0"
                                                            :disabled="!rowData.feb.needInput"
                                                            @input="changingF(index, 'feb')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.feb }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.mar.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.mar.value" min="0"
                                                            :disabled="!rowData.mar.needInput"
                                                            @input="changingF(index, 'mar')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.mar }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.apr.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.apr.value" min="0"
                                                            :disabled="!rowData.apr.needInput"
                                                            @input="changingF(index, 'apr')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.apr }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.may.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.may.value" min="0"
                                                            :disabled="!rowData.may.needInput"
                                                            @input="changingF(index, 'may')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.may }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.jun.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.jun.value" min="0"
                                                            :disabled="!rowData.jun.needInput"
                                                            @input="changingF(index, 'jun')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.jun }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.jul.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.jul.value" min="0"
                                                            :disabled="!rowData.jul.needInput"
                                                            @input="changingF(index, 'jul')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.jul }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.aug.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.aug.value" min="0"
                                                            :disabled="!rowData.aug.needInput"
                                                            @input="changingF(index, 'aug')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.aug }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.sep.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.sep.value" min="0"
                                                            :disabled="!rowData.sep.needInput"
                                                            @input="changingF(index, 'sep')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.sep }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.oct.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.oct.value" min="0"
                                                            :disabled="!rowData.oct.needInput"
                                                            @input="changingF(index, 'oct')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.oct }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.nov.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.nov.value" min="0"
                                                            :disabled="!rowData.nov.needInput"
                                                            @input="changingF(index, 'nov')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.nov }}</div>
                                                </b-td>
                                                <b-td>
                                                    <div class="input-div" v-if="rowData.dec.hasOwnProperty('needInput')">
                                                        <input class="input-f" v-model="rowData.dec.value" min="0"
                                                            :disabled="!rowData.dec.needInput"
                                                            @input="changingF(index, 'dec')" pattern="^[0-9]"
                                                            @keydown="isNumber" />
                                                    </div>
                                                    <div v-else>{{ rowData.dec }}</div>
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                        <b-tbody v-if="!actionMain.machineDetail || !actionMain.machineDetail.length">
                                            <tr>
                                                <td class="text-center" style="height: 380px" rowspan="6"
                                                    :colspan="machineDetailHeader.length">
                                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                                    <div class="ed-date">No Data Found</div>
                                                </td>
                                            </tr>
                                        </b-tbody>
                                        <b-tfoot> </b-tfoot>
                                    </b-table-simple>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>

            <div class="add-top-border">
                <label class="approval-heading">Approvals</label>
            </div>
            <div class="approval-border-top">
                <label class="col-9 persona-heading" style="text-align: left"> Select Work Center </label>
                <label class="col-3 persona-heading approval-border-left" style="text-align: center">
                    Plant Approval
                </label>
            </div>

            <div class="full-length-width display-flex">
                <div class="col-9 scroll" style="height: 120px !important" v-if="!isAllWorkcenterApproved">
                    <div id="approval-work-centers-id" class="approval-work-centers">
                        <div v-for="item in workCenterList" :key="item.workcenterId" class="individual-checkbox">
                            <div class="chk-container">
                                <b-form-checkbox type="checkbox" :checked="item.workcenterChecked"
                                    :name="'wc' + item.workcenterId" class="radio" @change="onWorkCenterCheck($event, item)"
                                    :disabled="item.approvalStatus">
                                    {{ item.workcenterName }}</b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-9 scroll" style="margin: 60px 0 !important; padding: 0 ;" v-if="isAllWorkcenterApproved">
                    <div class="approval-heading" style=" vertical-align: middle;">Please
                        set target for a work center and then
                        proceed to Mark Approval.
                    </div>
                </div>
                <div class="col-3 approval-border-left">
                    <div class="display-flex">

                        <div class="col-12 no-padding review-background-active display-flex-align-center">
                            <label class="approval-section-heading">
                                Lock & Approve
                                <b-form-checkbox id="executive-approve" v-model="exceutiveApprove"
                                    @change="executiveCheckbox($event, 'executive-review')" name="executive"
                                    style="justify-content: center" class="radio" :disabled="isAllWorkcenterApproved">
                                </b-form-checkbox>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 display-flex" style="flex-wrap: wrap">
                        <label class="col-12 approvals-updated-by">
                        </label>
                        <div class="col-12 approvals-save-button">
                            <b-button pill variant="outline-primary" class="btn cancel-save-btn ed-st-btn"
                                @click="$router.go(-1)"> Cancel </b-button>
                            <b-button pill variant="outline-primary" class="btn cancel-save-btn ed-st-btn"
                                v-b-modal.messagePopup :disabled="!exceutiveApprove" v-if="!isAllWorkcenterApproved"
                                @click="updateApproval">
                                Save
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MessagePopup :routToBack="true" class="alert" :message="errorMsg" :show="showPopup" :headText="headText" />
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import roles from "@/common/constants/roles";
import MessagePopup from "@/components/common/MessagePopup.vue";
export default {
    name: "CurrentScoreSetting",
    data() {
        return {
            selectedWorkCenter: ["ALL"],
            showLoader: true,
            plantList: [],
            workCenterList: [],
            committedYear: [],
            pillarList: [],
            plantId: 1,
            isAllWorkcenterApproved: false,
            exceutiveReview: true,
            exceutiveApprove: false,
            pillarId: 5,
            pillarIds: [],
            committedYr: new Date().getFullYear(),
            machineDetailHeader: [
                {
                    key: "workCenter",
                    label: "WORK CENTER",
                    sortable: false
                },
                {
                    key: "machines",
                    label: "MACHINES",
                    sortable: false
                },
                {
                    key: "jan",
                    label: "JAN",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "feb",
                    label: "FEB",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "mar",
                    label: "MAR",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "apr",
                    label: "APR",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "may",
                    label: "MAY",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jun",
                    label: "JUN",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jul",
                    label: "JUL",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "aug",
                    label: "AUG",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "sep",
                    label: "SEP",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oct",
                    label: "OCT",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "nov",
                    label: "NOV",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "dec",
                    label: "DEC",
                    sortable: false,
                    variant: "success"
                }
            ],
            collapsed_values: {},
            pillarWiseMachine: [],
            roles: roles,
            errorMsg: "",
            headText: "",
            showPopup: false,
            showOption: false,
            selectedText: "",
            selected: []
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MessagePopup
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 3
        });
        this.$store.dispatch("breadcrumb", {
            title: "Plant Optimization Step Audit - Target Approval",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication Step Audit", primary: false }
            ]
        });
    },
    mounted() { },
    created() {

        this.showLoader = false;

        this.getCommittedYear();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData", "pillarPrevSelected"]),
        checkedWorkCentersCount() {
            let count = 0;
            this.workCenterList.map((wc) => {
                if (!wc.approvalStatus && wc.workcenterChecked && wc.wcId) count++;
            });

            return count;
        }
    },
    methods: {
        showDropdown() {
            this.showOption = !this.showOption;
        },
        onConfirmClick() {
            this.showOption = false;
            console.log(this.selected);
            this.showLoader = true;
            this.pillarIds = this.selected.map((pillar) => pillar.pillarId);
            this.filterHandler();
        },
        onOptionCheck() {
            this.selectedText = "";
            this.selected.forEach((element) => {
                this.selectedText += element.regionName + ", ";
            });
            this.selectedText = this.selectedText.substring(0, this.selectedText.length - 2);
        },
        markAllOptionCheck() {
            this.selectedText = "";
            this.selected = JSON.parse(JSON.stringify(this.pillarList));
            this.selected.forEach((element) => {
                this.selectedText += element.regionName + ", ";
            });
            this.selectedText = this.selectedText.substring(0, this.selectedText.length - 2);
        },
        checkToggle(collapsedValue, pillarId) {
            console.log(collapsedValue, pillarId);
            this.collapsed_values[pillarId] = !collapsedValue;
        },
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.MRCOMMITTEDYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.committedYr = new Date().getFullYear();
                this.getPlantDetails();
            });
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getPillarDetails();
            });
        },
        getWorkCenterListForApproval() {
            let cYr = this.committedYr;
            let plntId = this.plantId.plantId;
            let plrId = this.pillarIds;
            ApiCalls.httpCallDetails(Api.GETWORKCENTERBYPLANT, "post", {
                cYear: cYr,
                plantId: plntId,
                pillarIds: plrId
            }).then((data) => {
                console.log(data.data);
                this.isAllWorkcenterApproved = true;
                data.data = data.data.filter((wc) => wc.isWorkcenterInitiated);
                this.workCenterList = data.data.map((wc) => {
                    if (!wc.approvalStatus) this.isAllWorkcenterApproved = false;
                    wc["workcenterChecked"] = true;
                    return wc;
                });

                this.workCenterList.sort((next, prev) => { if (next.workcenterName < prev.workcenterName) return -1; else return next.workcenterName > prev.workcenterName ? 1 : 0 });
                if (this.workCenterList.length)
                    this.workCenterList.unshift({
                        wcId: 0,
                        workcenterName: "All",
                        approvalStatus: false,
                        workcenterChecked: true
                    });
                if (this.isAllWorkcenterApproved) {
                    this.exceutiveReview = false;
                    this.exceutiveApprove = true;
                }
            });
        },
        plantChanged(event) {
            console.log("plantChanged");
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
        },
        getPillarDetails() {
            ApiCalls.httpCallDetails(Api.MRPILLARDETAILS, "get").then((data) => {
                this.pillarList = data.data.map((pillar) => ({
                    pillarId: pillar.pillarId,
                    pillarName: pillar.pillarName,
                    pillarCode: pillar.pillarCode,
                    active: "Y",
                    checkedBox: true,
                    regionName: pillar.pillarCode
                }));
                this.pillarIds = this.pillarList.map((pillar) => pillar.pillarId);
                this.markAllOptionCheck();
                this.filterHandler();
                this.getWorkCenterListForApproval();
            });
        },
        pillarChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("pillarPrevSelected", event.target.value);
            this.filterHandler();
        },
        filterHandler() {

            let cYr = this.committedYr;
            let plntId = this.plantId.plantId;
            let plrId = this.pillarIds;
            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.APPROVETARGETSETTING, "post", {
                cYear: cYr,
                plantId: plntId,
                pillarId: plrId
            }).then((data) => {
                this.showLoader = false;
                console.log(data.data);
                let resData = data.data;
                this.pillarWiseMachine = [];
                resData.forEach((item, pos) => {
                    let eachPillarWise = {
                        pillarId: item.pillarId,
                        pillarName: item.list[0].pillarName,
                        pillarCode: item.list[0].pillarCode,
                        machineDetail: []
                    };

                    this.$set(this.collapsed_values, item.list[0].pillarCode.pillarCode, true);
                    let temp = {};
                    let tempArr = [];
                    item.list.sort((a, b) => {
                        if (a.wcId > b.wcId) return 1;
                        else if (a.wcId < b.wcId) return -1;
                        return 0;
                    });
                    item.list.forEach((m, index) => {
                        temp[m.workcenterName] = temp[m.workcenterName] ? temp[m.workcenterName] + 1 : 1;
                    });
                    item.list.forEach((machine, index) => {
                        let tempObj = {
                            machines: machine.machineName,
                            jan: machine.jan,
                            feb: machine.feb,
                            mar: machine.mar,
                            apr: machine.apr,
                            may: machine.may,
                            jun: machine.jun,
                            jul: machine.jul,
                            aug: machine.aug,
                            sep: machine.sep,
                            oct: machine.oct,
                            nov: machine.nov,
                            dec: machine.dec,
                            addBorder: false
                        };
                        if (index > 0 && item.list[index].workcenterName !== item.list[index - 1].workcenterName) {
                            tempObj["workCenter"] = {
                                name: machine.workcenterName,
                                noOfMachine: temp[machine.workcenterName]
                            };
                            tempObj.addBorder = true;
                        } else if (index === 0) {
                            tempObj["workCenter"] = {
                                name: machine.workcenterName,
                                noOfMachine: temp[machine.workcenterName]
                            };
                        }
                        tempArr.push(tempObj);
                    });
                    eachPillarWise.machineDetail = JSON.parse(JSON.stringify(tempArr));
                    this.pillarWiseMachine.push(eachPillarWise);
                    console.log("Temp", eachPillarWise);
                });
            });
        },
        isNumber(evt) {

            let charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        changingF(a, b) {
            console.log(a, b);
        },
        executiveCheckbox(event, id) {
            if (event === true && id === "executive-review" && this.exceutiveReview === true) {
                this.exceutiveReview = false;
            }
            if (event === true && id === "executive-approve" && this.exceutiveApprove === true) {
                this.exceutiveApprove = false;
            }
            this.approveBy = "rm";
        },
        onWorkCenterCheck(event, rowData) {
            console.log(event);
            if (rowData.workcenterName === "All") {
                this.workCenterList.forEach((wc) => {
                    if (!wc.approvalStatus) wc.workcenterChecked = event;
                });
                return;
            }
            let index = this.workCenterList.findIndex((wc) => wc.wcId === rowData.wcId);
            this.workCenterList[index].workcenterChecked = event;
            let totalWC = this.workCenterList.length - 1;
            let noOfChecked = 0;
            this.workCenterList.forEach((wc) => {
                if (!wc.approvalStatus && wc.wcId && wc.workcenterChecked) noOfChecked++;
            });
            console.log(totalWC, noOfChecked);
            this.workCenterList[0].workcenterChecked = totalWC === noOfChecked ? true : false;
        },
        updateApproval() {
            const reqBody = [];
            this.workCenterList.map((wc) => {
                this.pillarIds.forEach((pllr) => {
                    if (!wc.approvalStatus && wc.workcenterChecked && wc.wcId)
                        reqBody.push({
                            wcId: wc.wcId,
                            plantId: this.plantId.plantId,
                            commitedYear: this.committedYr,
                            approverL1: this.$oidc.userProfile.email,
                            approverL2: null,
                            status: this.exceutiveApprove ? "Approved" : "Pending",
                            pillarId: pllr
                        });
                });
            });
            console.log(reqBody);
            this.showPopup = false;
            this.errorMsg = "";
            this.headText = "";
            this.showLoader = true;
            if (this.isPayloadValid()) {
                ApiCalls.httpCallDetails(Api.SAVEWORKCENTERBYPLANT, "post", reqBody).then((data) => {
                    console.log(data);
                    this.showLoader = false;
                    this.getWorkCenterListForApproval();
                    this.filterHandler();
                    if (data.success) {
                        this.errorMsg = "Target Approval is Successfully Completed"; //data.message.trim
                        this.headText = "";
                        this.showPopup = true;
                    } else if (!data.success) {
                        this.errorMsg = "Something went wrong.";
                        this.headText = "";
                        this.showPopup = true;
                    }
                });
            }
            else
                this.showLoader = false;
        },
        isPayloadValid() {
            this.showPopup = false;
            let valid = true;
            if (!this.checkedWorkCentersCount) {
                valid = false;
                this.errorMsg = "Please select at least one work center";
                this.headText = "Alert";
                this.showPopup = true;
            }
            if (!this.selected.length) {
                valid = false;
                this.errorMsg = "Please select at least one pillar";
                this.headText = "Alert";
                this.showPopup = true;
            }
            return valid;
        }
    },
};
</script>

<style scoped>
.approval-heading {
    color: #234372;
    font-size: 1.3333333333333333rem;
    font-family: "BarlowSb", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.graph-div {
    width: 25%;
    padding: 0 1%;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.scroll {
    overflow: auto;
    max-height: 450px !important;
    height: auto !important;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-needInput-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.add-top-border {
    border-top: 1px solid #eae6e6;
}

.add-bottom-border {
    border-bottom: 1px solid #eae6e6;
}

.machine-action-tracker {
    margin-top: 2rem;
    width: 100%;
}

.edit-button-div {
    display: flex;
}

.input-div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    /* width: 50px; */
}

.input-f {
    width: 30px;
    text-align: center;
    font-size: 1.33rem;
    border-radius: 4px;
    border: #313131 1px solid;
}

#machine-action-tracker th.table-success {
    background-color: #f4f6fd;
}

#machine-action-tracker th:first-child {
    background-color: white !important;
    font-weight: medium;
}

#machine-target-setting th:first-child,
#machine-target-setting td:first-child {
    text-align: left !important;
    padding-left: 2rem !important;
}

#machine-target-setting tr td {
    border: 0px !important;
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-target-setting-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-action-table-approve {
    max-height: 8rem !important;
}

/* .machine-action-table { */
/* max-height: 37rem !important; */
/* } */


.custom-control {
    display: flex !important;
}

.img-button {
    cursor: pointer;
    background-color: white;
    border-color: white;
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
}

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
    min-width: 40px;
}

.save-submit-btn {
    background-color: #274ba7;
    border-color: #274ba7 !important;
    margin-top: 0.4rem;
}

.save-submit-btn:hover {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:active {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:focus {
    box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

/* ///// MUlti Select////// */

.dropdown {
    position: relative;
    cursor: pointer;
}

.option-text {
    margin: 0 1rem;
    font-size: 1.34rem !important;
    font-family: "BarlowM", sans-serif !important;
}

.flex-div {
    display: flex;
    align-items: center;
    flex: 10;
}

.multi-select {
    position: relative;
}

.multi-select ul {
    border: 1px solid #ddd;
    box-shadow: 2px 1px 4px 0.1rem rgb(0 0 0 / 25%);
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    list-style: none;
    max-height: 150px;
    z-index: 2;
    background-color: #fff;
    overflow: auto;
}

.select-lable {
    z-index: 2;
}

.save-button {
    background-color: #eff3ff !important;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowM", sans-serif;
    width: 7.5rem;
    height: 3rem;
    border-color: #869ed8;
    margin-left: 1rem;
}

.validation-btn {
    padding: 0 1rem;
}

.validation-btn:hover {
    color: #274ba7 !important;
    background: #274ba741 0% 0% no-repeat padding-box !important;
}

div.multi-select>ul>li:nth-child(n) {
    padding: 0.2rem;
}

/* ///// MUlti Select////// */

#machine-action-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th:first-child {
    width: 15%;
}

.machine-action-table td,
.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}


.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table th:first-child,
.machine-action-table td:first-child {
    background-color: transparent !important;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.level-status {
    color: #454545;
}

.pillar-count {
    font-size: 1rem;
    font-family: "BarlowB", sans-serif;
    padding: 0.25rem;
    background-color: #d55050 !important;
    color: #fff;
    border-radius: 50%;
    margin-left: 0.4rem;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
}

.principle-name-label {
    margin: 1rem 0;
    vertical-align: middle;
    font-size: 1.33rem;
    font-family: "BarlowM", sans-serif;
    line-height: 1.6666666666666667rem;
}

.machine-action-table tr {
    height: 5rem;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.btn {
    margin: 0 0 0 1rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}

.approval-work-centers {
    flex: 1 1 0%;
    display: flex;
    max-height: 10rem;
    flex-flow: row wrap;
    align-content: stretch;
}

.approval-border-top {
    border-top: 1px solid #f0f0f0;
}

.approval-border-right {
    border-right: 1px solid #f0f0f0;
}

.approval-border-left {
    border-left: 1px solid #f0f0f0;
}

.individual-checkbox {
    /* min-width: 30%;
    max-width: 30%; */
    /* width: 33%; */
    width: 14rem;
}

.review-background-active {
    background-color: #f0f7ff;
    border-radius: 6px;
    padding: 2rem 0;
}

.persona-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.approvals-save-button {
    padding: 1rem;
    float: right;
    text-align: center;
}


.approval-section-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}



.display-flex-align-center {
    margin-left: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.approval-section-heading-disabled {
    color: #313131;
    opacity: 0.2;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}

.approvals-updated-by {
    color: #5a5a5a;
    font-size: 0.9166666666666666rem;
    font-family: "BarlowR", sans-serif;
    text-align: center;
    padding: 1rem;
}

.table-container {
    height: 500px;
    width: 800px;
    overflow: auto;
}

.table-container table {
    height: 100%;
    width: 100%;
}

.chk-container {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 10px 10px 10px 10px;
}


@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
